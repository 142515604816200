
import { TestCategoryDocument, TestNameDocument, TestSubdivisionDocument, TestTerminalDocument, TestTypeDocument } from "../dto";
import { TestCategory } from './test-category'; 
import { TestSubdivision } from './test-subdivision';
import { TestTerminal } from "./test-terminal";
import { TestType } from "./test-type";
import { TestName } from './test-name';
import { TestSubtype } from './test-subtype';
import { TestDistrict } from "./test-district";

export type TestTypeName = 'test-category' | 'test-type' | 'test-subdivision' | 'test-name' | 'test-terminal' | 'test-subtype' | 'test-district';
export type TestObjectType<T> =
  T extends 'test-category' ? TestCategoryDocument :
  T extends 'test-name' ? TestNameDocument :
  T extends 'test-subdivision' ? TestSubdivisionDocument :
  T extends 'test-terminal' ? TestTerminalDocument :
  T extends 'test-type' ? TestTypeDocument :
  T extends 'test-subtype' ? TestSubtype :
  T extends 'test-district' ? TestDistrict :

  never;

  export type TestClassType<T> =
    T extends 'test-category' ? TestCategory :
    T extends 'test-name' ? TestName :
    T extends 'test-subdivision' ? TestSubdivision :
    T extends 'test-terminal' ? TestTerminal :
    T extends 'test-type' ? TestType :
    T extends 'test-subtype' ? TestSubtype :
    T extends 'test-district' ? TestDistrict :

    never;

export const TestClassEnum = {
  'test-category': TestCategory,
  'test-name': TestName,
  'test-subdivision': TestSubdivision,
  'test-terminal': TestTerminal,
  'test-type': TestType,
  'test-subtype': TestSubtype,
  'test-district': TestDistrict,

};