import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin-app';
  constructor(
    // need to bring in here so guards all work
    private authService: AuthService,
  ) {}
  
  public ngOnInit(): void {
    document.addEventListener('DOMNodeInserted', function () {
      const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });
  }
}
