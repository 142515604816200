import { TimePeriod } from '../../enums/qualifications';

export enum QualificationRequirementType {
  CHECKLIST = 'checklists',
  OPERATIONS_TEST = 'test-name',
  EXTERNAL_REQUIREMENT = 'external-requirements',
}

export interface QualificationRefreshPeriod {
  period: TimePeriod;
  quantity: number;
}

//@todo model equivalents
export interface QualificationRequirementDoc {
  groupId: string;
  qualificationId: string;
  id: string;
  path: string;
  name: string;
  expirationPeriod: QualificationRefreshPeriod;
  reset?: boolean;
  hidden?: boolean;
  isRefresherTraining?: boolean;
  mustCompleteWithinXDays?: number;
}
