<div class="logo wrapper justify-center align-center">
   <img src="{{ logo }}" />
</div>
<div class="wrapper justify-center align-center">
  <form (keyup.enter)="signIn()">
  <mat-card appearance="outlined" style="padding:20px">
    <mat-card-title><h1>Login</h1></mat-card-title>
    <mat-card-content style="padding:0px">
      <app-login-form [form]="form"></app-login-form>
    </mat-card-content>
    <div class="justify-around" style="padding-top: 30px">
      <button data-cy="login-button" mat-raised-button color="primary" (click)="signIn()">LOGIN</button>
    </div>
  </mat-card>
</form>
</div>
<div class="footer flex justify-center">
<p class="password-message">Contact <a href="mailto:support@sparkts.net?subject=Password Assistance">support@sparkts.net</a> for password assistance.</p>
</div>
<div class="flex justify-center" style="padding-top: 40px">
<button style="color: white" data-cy="login-button" mat-raised-button color="accent" (click)="enterpriseSignIn()">ENTERPRISE LOGIN</button>
</div>
<div class="justify-center">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>

