export class PaginatedRecordSearchQuery {
  public page: number;
  public limit: number;
  public orderBy: string;
  public sortOrder: 'ASC' | 'DESC';
  public search: string;
  public categoryId?: string;
  public employeeId?: string;
  public subdivisionId?: string;
  public groupId?: string;
  public jobTitle?: string;
  public jobDuty?: string;
  public budgetGroup?: string;
  public costCenter?: string;
  public division?: string;
  public zone?: string;
  public payId?: string;
  public start: string; // iso string
  public end: string; // iso string
  public startDate: string; // iso string
  public endDate: string; // iso string
  public location: string;
  public group: string;
  public testName : string;
  public category: string;

  constructor(props: Partial<PaginatedRecordSearchQuery>) {
    if (props) {
      this.assignValues(props);
    }
  }

  private assignValues(val: Partial<PaginatedRecordSearchQuery>) {
    this.page = val.page || 0;
    this.limit = val.limit || null;
    this.orderBy = val.orderBy || null;
    this.sortOrder = val.sortOrder || 'ASC';
    this.search = val.search || null;
    const today = new Date;
    this.start = val.start || new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
    this.end = val.end || new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
    this.startDate = val.startDate || new Date('2000-01-01').toISOString();
    this.endDate = val.endDate || new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
    this.location = val.location || null;
    this.group = val.group || null;
    if (val.categoryId) { this.categoryId = val.categoryId };
    if (val.category) {this.category = val.category};
    if (val.employeeId) { this.employeeId = val.employeeId };
    if (val.subdivisionId) { this.subdivisionId = val.subdivisionId };
    if (val.groupId) { this.groupId = val.groupId };
    if (val.jobTitle) {this.jobTitle = val.jobTitle};
    if (val.jobDuty) {this.jobDuty = val.jobDuty};
    if (val.budgetGroup) {this.budgetGroup = val.budgetGroup};
    if (val.costCenter) {this.costCenter = val.costCenter};
    if (val.division) {this.division = val.division};
    if (val.zone) {this.zone = val.zone};
    if (val.payId) {this.payId = val.payId};
    if (val.testName) {this.testName = val.testName};

  }

  build() {
    return { ...this };
  }
}