<form [formGroup]="form" fxLayout="column">
  <div fxLayout="column">
    <mat-form-field>
      <input data-cy="login-input" matInput placeholder="Username" formControlName="username">
      <mat-error class="mat-caption mat-text-warn" *ngIf="form.controls['username'].hasError('username') && form.controls['username'].touched">
        <mat-icon>warning</mat-icon>
        Please enter a username.
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="column">
    <mat-form-field>
      <input data-cy="password-input" matInput type="password" placeholder="Password" formControlName="password">
      <mat-error class="mat-caption mat-text-warn" style="font-size: 12px;" *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched">
        <mat-icon>warning</mat-icon>
        Please enter your password.
      </mat-error>
    </mat-form-field>
  </div>
</form>
