import { KeywordDto } from '../dto';

export class Keyword implements KeywordDto {
  id: number;
  tenantId: number;
  archived: boolean;
  createdOn: Date;
  lastUpdatedOn: Date;
  createdBy: string;
  updatedBy: string;
  name: string;

  constructor(keyword?: Keyword | KeywordDto) {
    this.id = keyword.id || null;
    this.tenantId = keyword.tenantId || null;
    this.archived = keyword.archived || false;
    this.createdOn = keyword.createdOn || new Date();
    this.lastUpdatedOn = keyword.lastUpdatedOn || new Date();
    this.createdBy = keyword.createdBy || null;
    this.updatedBy = keyword.updatedBy || null;
    this.name = keyword.name;
  }

}
