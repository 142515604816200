<div class="logo wrapper justify-center align-center">
  <img src="assets/images/railtasker-logo.png" />
</div>

<div class="justify-center">
  <a [routerLink]="['/sso/ns/']">
    <img class="title-image" src="assets/images/ns-logo.png" width="200"/>
  </a>
</div>
<div class="justify-center">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>

