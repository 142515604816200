import firebase from 'firebase/compat/app';

import { QualificationDoc } from './qualificationDoc';
import { UserDocument } from '..';
import { BaseAlgoliaObject } from '../algoliaObject';

interface QualificationSystemRenewalSource {
  type: 'system';
  functionId: string;
}

interface QualificationUserRenewalSource {
  type: 'user';
  userId: string;
}

export interface QualificationRenewal {
  date: firebase.firestore.Timestamp | Date;
  source: QualificationSystemRenewalSource | QualificationUserRenewalSource;
}

export interface QualificationAssignmentDoc
  extends Omit<QualificationDoc, 'requirements' | 'countUserAssignment' | 'countUserExpiring' | 'countUserExpired' | 'requirementGroups'>,
    Pick<UserDocument, 'firstName' | 'lastName' | 'username' | 'employeeId'> {
  qualificationId: string;
  qualificationPath: string;

  assigned: boolean;
  // status can be updated manually or by the platform
  status: QualificationAssignmentStatus;
  assignmentDate?: firebase.firestore.Timestamp;
  expirationDate?: firebase.firestore.Timestamp;
  qualificationDate?: firebase.firestore.Timestamp;
  initialQualificationDate?: firebase.firestore.Timestamp;
  totalProgress?: number;
  comments?: string;
  revocationPeriod?: number;
  revocationDate?: firebase.firestore.Timestamp;

  // audit past renewals
  // renewalLog?: QualificationRenewal[];

  //user data
  jobTitle: string;
  userId: string;
  userActive: boolean;
  zone?: string;
  division?: string;
  location?: string;
  costCenter?: string;
  budgetGroup?: string;
  payId?: string;
  groupName: string;
  /**
   * Store the latest event that satisfies a particular requirement group
   */
  requirementGroupCompletionEvents: Record<string, QualificationAssignmentRequirementEvent>;
  allCompletionEvents?: QualificationAssignmentRequirementEvent[];
}

export interface QualificationAssignmentRequirementEvent {
  path: string;
  name: string;
  completedOn?: firebase.firestore.Timestamp;
  expiresOn?: firebase.firestore.Timestamp;
  status: QualificationAssignmentRequirementEventStatus;
  requirementRefId: string;
  isRefresherTraining?: boolean;
  mustCompleteWithinXDays?: number;
}

export enum QualificationAssignmentStatus {
  REVOKED = 'revoked', // the user was previously qualified, but an administrator has manually de-certified the user for some reason

  EXPIRED = 'expired', // user was previously qualified, but is outside the qualification renewal window
  INVALID = 'invalid', // the user was previously qualified, but one or more of their requirements has lapsed within the qualification renewal window
  RESTRICTED = 'restricted', // the user was previously certified, but one or more of their requirements has lapsed within the certification renewal window
  STUDENT = 'student', // user has been assigned the qualification, but has not satisfied one or more requirement groups
  SUSPENDED = 'suspended', // the user was previously qualified, but one or more of their requirements has lapsed within the qualification renewal window
  QUALIFIED = 'qualified', // meets all requirement groups and within the qualification renewal window
  CERTIFIED = 'certified', // same as qualifications, but for certifications
  PENDING = 'pending', // user has met all requirement groups within the qualification for the first time, but b/c the qualification type is `certification` an admin must manually move this into `pass` state
  DESIGNATED = 'designated', //user was deemed to have been qualified; we will use the initial qualification date to determine the _next_ expiration date
  //additional ns certification statuses
  FRA_REVOKED = 'fra revoked',
  FRA_SUSPENDED = 'fra suspended',
  GRANDFATHERED = 'grandfathered',
  INACTIVE = 'inactive',
  TRAINEE = 'trainee',
  MED_DQ = 'medical disqualification',
  MED_HOLD = 'medical hold',
  REMOVED = 'removed'
}

export const ActiveQualificationAssignmentStatuses: QualificationAssignmentStatus[] = [
  QualificationAssignmentStatus.CERTIFIED,
  QualificationAssignmentStatus.QUALIFIED,
  QualificationAssignmentStatus.DESIGNATED,
];

export enum QualificationAssignmentRequirementEventStatus {
  FAIL = 'fail', // failed all requirements in the requirement group
  PASS = 'pass', // passed one ore more requirements in the requirement group
  INCOMPLETE = 'incomplete', // has not passed or failed any requirement in the requirement group
}

export interface AssignQualificationToUsersRequest {
  qualificationId: string;
  userIds: string[];
  designate?: boolean;
}

export interface GetCompletionsForQualificationAssignmentRequest {
  assignmentId: string;
  tenantId: string;
  requirementRefId?: string;
}

export interface RecomputeQualificationStatusRequest {
  assignmentId: string;
}

export interface CountsForQualificationsRequest {
  qualificationIds: string[];
}

export interface AlgoliaQualificationAssignmentObject
  extends Pick<QualificationAssignmentDoc, 'firstName' | 'lastName' | 'jobTitle' | 'employeeId' | 'username' | 'lastUpdatedOn'>,
    BaseAlgoliaObject {}
