import firebase from 'firebase/compat/app';

import { UserObj } from "./user";
import { ChecklistAssigned } from "./checklistAssigned";
import { QualifiedPersonDoc } from "../dto";
import { UserDocument } from "../dto/userDoc";
import { ChecklistAssignedDocument } from "../dto/checklistAssignedDoc";
import { DateTime } from 'luxon';

export class QualifiedPerson implements QualifiedPersonDoc {
  archived: boolean;
  assignQPasDI?: boolean;
  createdOn: Date;
  checklistId: string;
  checklistName: string;
  checklistAssigned?: ChecklistAssignedDocument;
  designatedInstructorOnly: boolean;
  expireQualifiedPerson?: number; // in days id: string;
  expireQualifiedPersonDate?: Date;  // Model uses Date, Doc(interface) use firestore.Timestamp
  id: string;
  lastUpdatedOn: Date;
  userId: string;
  userName: string;
  user?: UserDocument;

  constructor(qp: QualifiedPerson | QualifiedPersonDoc) {
    if (qp.archived === true || qp.archived === false) {
      this.archived = qp.archived;
    } else {
      this.throwError('Archived');
    }
    if (qp.checklistId) {
      this.checklistId = qp.checklistId;
    } else {
      this.throwError('Checklist Id');
    }
    if (qp.checklistName) {
      this.checklistName = qp.checklistName;
    } else {
      this.throwError('Checklist Name');
    }
    if (qp.createdOn) {
      this.createdOn = this.createDate(qp.createdOn);
    }
    if (qp.lastUpdatedOn) {
      this.lastUpdatedOn = this.createDate(qp.lastUpdatedOn);
    }
    if (qp.designatedInstructorOnly === true || qp.designatedInstructorOnly === false) {
      this.designatedInstructorOnly = qp.designatedInstructorOnly;
    } else {
      this.throwError('Designated Instructor Only');
    }
    if (qp.id) {
      this.id = qp.id;
    }
    if (qp.lastUpdatedOn) {
      this.lastUpdatedOn = this.createDate(qp.lastUpdatedOn);
    }
    if (qp.userId) {
      this.userId = qp.userId;
    } else {
      this.throwError('UserId');
    }
    if (qp.userName) {
      this.userName = qp.userName;
    } else {
      this.throwError('Username');
    }
    if (qp.assignQPasDI) {
      this.assignQPasDI = qp.assignQPasDI;
    } else {
      this.assignQPasDI = false;
    }
    if (qp.user) { this.user = qp.user };
    if (qp.checklistAssigned) { this.checklistAssigned = qp.checklistAssigned };
    if (qp.expireQualifiedPerson) { this.expireQualifiedPerson = qp.expireQualifiedPerson };
    if (qp.expireQualifiedPersonDate) {
      this.expireQualifiedPersonDate = this.createDate(qp.expireQualifiedPersonDate);
    }
  }

  createDate(dateObj: Date | firebase.firestore.Timestamp | string | any): Date {
    // console.info('gsfsdate dateObj:', dateObj);
    if (!dateObj) {
      return null;
    }
    if (dateObj instanceof(Date)){
      return dateObj;
    }
    if (typeof dateObj === 'string'){
      return new Date(dateObj.toString());
    }
    if (dateObj.seconds){
      return dateObj.toDate();
    }
    if (dateObj._seconds) {
      return new Date(dateObj._seconds * 1000);
    }
    return null;
  }

  setUser(user: UserObj) {
    this.user = user;
  }

  setChecklistAssigned(checklistAssigned: ChecklistAssigned) {
    this.checklistAssigned = checklistAssigned;
  }

  csvData() {
    return {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      status: this.checklistAssigned && this.checklistAssigned.status || null,
      // @ts-ignore
      dateCompleted: this.checklistAssigned && this.checklistAssigned.dateCompleted && DateTime.fromJSDate(this.createDate(this.checklistAssigned.dateCompleted)).toFormat('MM/dd/yy') || '',
      username: this.user.username,
      employeeId: this.user.employeeId,
      group: this.user.groupName,
      title: this.user.title,
      checklistName: this.checklistAssigned && this.checklistAssigned.checklistName || null,
      checklistId: this.checklistAssigned && this.checklistAssigned.checklistUniqueId || null,
      expirationDate: this.expireQualifiedPersonDate ? DateTime.fromJSDate(this.expireQualifiedPersonDate).toFormat('MM/dd/yy') : '',
    }
  }

  valid(): boolean {
    return true; // TODO implement validation logic.
  }

  get expired() {
    if (this.expireQualifiedPerson) {
      if (this.checklistAssigned.dateCompleted && this.checklistAssigned.expireQualifiedPersonDate) {
        return (this.checklistAssigned.dateCompleted < this.checklistAssigned.expireQualifiedPersonDate);
      }
    }
    return false;
  }

  private throwError(param: string) {
    throw new Error(`${param} is required for QP`);
  }
}
