import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { Kcs2Component } from './auth/sso/kcs2/kcs2.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { CallbackComponent } from './auth/sso/callback/callback.component';
import { Auth0Component } from './auth/sso/auth0/auth0.component';
import { BnsfComponent } from './auth/sso/bnsf/bnsf.component';
import { LogoutSuccessComponent } from './auth/logout-success/logout-success.component';
import { EnterpriseComponent } from './auth/sso/enterprise/enterprise.component';
import { NsComponent } from './auth/sso/ns/ns.component';
import { CpkcComponent } from './auth/sso/cpkc/cpkc.component';
import { ConrailComponent } from './auth/sso/conrail/conrail.component';
import { OmnitraxComponent } from './auth/sso/omnitrax/omnitrax.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard/users']);
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin},
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: '',
        redirectTo: 'dashboard/users',
        pathMatch: 'full',
      },
    ]
  },
  { path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToDashboard}
  },
  { path: 'logout',
    component: LogoutComponent
  },
  { path: 'logout-success',
    component: LogoutSuccessComponent
  },
  { path: 'sso/kcs-secureauth',
  component: Kcs2Component,
  canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  { path: 'sso/auth0',
    component: Auth0Component,
  },
  { path: 'sso/bnsf',
  component: BnsfComponent,
  canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  { path: 'sso/cpkc',
    component: CpkcComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  { path: 'sso/conrail',
    component: ConrailComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  { path: 'sso/omnitrax',
    component: OmnitraxComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  { path: 'sso/callback/:token',
    component: CallbackComponent,
  },
  {
    path: 'enterprise/login',
    component: EnterpriseComponent
  },
  { path: 'sso/ns',
    component: NsComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard/users',
    pathMatch: 'prefix'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
