export class CsvOptions {
  fieldSeparator = ',';
  quoteStrings = '"';
  decimalSeparator = '.';
  showLabels = true;
  showTitle = true;
  title: string;
  useTextFile = false;
  useBom = true;
  filename: string;
  headers: string[] = [];
  [key: string]: any;

  public constructor(properties?: any) {
    Object.assign(this, properties);
  }

  setTitle(title: string) {
    this.title = title;
  }

  setFilename(filename: string) {
    this.filename = filename;
  }

  setHeaders(header: string | string[]) {
    if (Array.isArray(header)) {
      this.headers = [ ...this.headers, ...header]
    } else {
      this.headers = [ ...this.headers, header]
    }
  }

  setParam(param: string, value: any) {
    this[param] = value;
  }

  build() {
    if (!this.title) {
      throw new Error('Title is required');
    }
    if (!this.filename) {
      throw new Error('Filename is required');
    }
    return { ...this }
  }
}
