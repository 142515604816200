import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements OnInit {
  public loading = false;
  public logo = `../../../assets/images/${environment.envName.includes('peopletasker') ? 'peopletasker' : 'railtasker'}-logo.png`;


  constructor() { }

  ngOnInit() {
    // window.location.href = `${environment.samlServiceUrl}/admin/bnsf/login`;
  }

}
