import 'isomorphic-fetch'; // Needed for IE11 as polyfill for fetch
import 'es6-promise';
import 'es7-object-polyfill';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/compat/analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ChartsModule } from 'ng2-charts';

import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/auth.guard';
import { SharedModule } from './shared/shared.module';

import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { NavDrawerComponent } from './layout/nav-drawer/nav-drawer.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SETTINGS as SETTINGS_FIRESTORE } from '@angular/fire/compat/firestore';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@NgModule({
  declarations: [AppComponent, DashboardLayoutComponent, NavDrawerComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChartsModule,
    SharedModule,
    InfiniteScrollModule,
    MatSnackBarModule,
  ],
  providers: [
    AuthGuard,
    ScreenTrackingService,
    UserTrackingService,
    AngularFireAuthGuard,
    HttpClient,
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: SETTINGS_FIRESTORE,
      useValue: { experimentalForceLongPolling: true, ignoreUndefinedProperties: true },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', subscriptSizing: 'dynamic' }},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { scrollStrategy: new NoopScrollStrategy() },
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
