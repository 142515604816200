import { createDate } from '../utils/dates/create-date';
import { TestNameDetailDocument, TestNameDocument } from '../dto';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

export class TestName implements TestNameDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string; // AccountHolder Id

  label: string;
  number: string;
  description?: string;
  parentId: string;
  categoryName: string;


  constructor(name: TestName | TestNameDocument) {
    this.assignValues(name);
  }

  assignValues(name: TestName | TestNameDocument) {
    this.archived = name.archived === false ? name.archived : name.archived || undefined;
    this.createdBy = name.createdBy || undefined;
    this.id = name.id || undefined;
    this.label = name.label || undefined;
    this.parentId = name.parentId || undefined;
    this.categoryName = name.categoryName || undefined;
    this.description = name.description || null; // description is optional in dto
    this.number = name.number || undefined;
    this.createdOn = name.createdOn ? createDate(name.createdOn) : undefined;
    this.lastUpdatedOn = name.lastUpdatedOn ? createDate(name.lastUpdatedOn) : undefined;
  }

  firestoreObject() {
    return { ...this };
  }
}

// Created to extend test name class by adding attributes
// not saved in Firestore: record count and category name
// Used in the ops module Tests tab table
export class TestNameDetail extends TestName {
  recordCount: string;

  constructor(params: TestNameDetail | TestNameDetailDocument) {
    const { recordCount, categoryName, ...testName } = params;

    super(params);
    this.recordCount = recordCount;
    this.categoryName = categoryName;
  }
}

export class TestNameSearchModel {
  doc: TestNameDocument;
  id: string;
  tenantId: string;
  constructor(doc: QueryDocumentSnapshot<TestNameDocument>, tenantId: string) {
    this.doc = doc.data();
    this.id = doc.id;
    this.tenantId = tenantId;
  }

  public toAlgolia() {
    const { archived, label, description, createdOn, lastUpdatedOn } = this.doc;
    const searchObj = {
      objectID: this.id,
      tenantId: this.tenantId,
      archived,
      label,
      description,
      //https://www.algolia.com/doc/guides/sending-and-managing-data/prepare-your-data/in-depth/what-is-in-a-record/#dates
      createdOn: new Date(createdOn?.valueOf()).getTime(),
      lastUpdatedOn: new Date(lastUpdatedOn?.valueOf()).getTime(),
    };
    return searchObj;
  }
}
