import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public logo = '';

  constructor(
    private authService: AuthService,
    private tenantService: TenantService,
    private userService: UserService,
    private router: Router,
  ) { }

  async ngOnInit() {
    try {
      const currentTenant = await this.tenantService.getUserTenant(this.userService.currentUser)
        .pipe(take(1)).toPromise();
      this.logo = `../../../assets/images/${currentTenant.isPeopleTasker ? 'peopletasker' : 'railtasker'}-logo.png`;
      this.authService.signOut()
        .then(() => {
          if (currentTenant && currentTenant.ssoLogout) {
            const logoutUrl =
            `${environment.samlServiceUrl}/admin/logout?tenant=${currentTenant.id}&name_id=${this.userService.currentUser.employeeId}`;
            this.userService.clearUser();
            window.open(logoutUrl, '_self');
            return;
          }
          this.userService.clearUser();
          this.router.navigate(['/login']);
        });
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }
  }
}
