export class QualifiedPersonQuery {
  public page: number;
  public limit: number;
  public orderBy: string;
  public sortOrder: 'ASC' | 'DESC';
  public search: string;
  public checklistId: string;

  constructor(val: Partial<QualifiedPersonQuery>) {
    if (val) {
      this.assignValues(val);
    }
  }

  private assignValues(val: Partial<QualifiedPersonQuery>) {
    this.page = val.page || 0;
    this.limit = val.limit || null;
    this.orderBy = val.orderBy || 'user.firstName';
    this.sortOrder = val.sortOrder || 'ASC';
    this.search = val.search || null;
    this.checklistId = val.checklistId || null;
  }

  setLimit(val: number) {
    this.limit = val;
  }

  setOrderBy(val: string) {
    this.orderBy = val;
  }

  setSortOrder(val: 'ASC' | 'DESC') {
    this.sortOrder = val;
  }

  setPage(val: number) {
    this.page = val;
  }

  setSearch(val: string) {
    this.search = val;
  }

  setChecklistId(val: string) {
    this.checklistId = val;
  }

  build() {
    return { ...this };
  }

  private throwError(param: string) {
    throw new Error(`${param} is required for QP query`);
  }
}

