import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Tenant } from '../../../../../core/models/tenant';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UserObj } from '../../../../../core/models/user';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

export interface CreateTenantData {
  id: string;
  name: string;
  apiKey: string;
  qualifiedPersonAsDesignatedInstructor: boolean;
  operationsModule: boolean;
  onboardingModule: boolean;
  qualificationsModule:  boolean;
  daModule: boolean;
  safetyAlertsModule: boolean;
  formFoxEnabled: boolean;
  address?: string;
  city?: string;
  contactName?: string;
  contactNumber?: string;
  postalCode?: string;
  state?: string;
  email: string;
  password: string;
  username: string;
  group: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  isPeopleTasker?: boolean;

}

@Injectable({ providedIn: 'root' })
export class TenantService {
  private _tenant = new BehaviorSubject<Tenant>(null);
  public tenant = this._tenant.asObservable();
  public currentTenant = this._tenant.pipe(take(1)).toPromise();

  constructor(
    private afs: AngularFirestore,
    private userService: UserService,
    private fns: AngularFireFunctions,
  ) {
    this.userService.user
      .pipe(
        switchMap((user) => {
          if (user) {
            return this.getUserTenant(user);
          }
          return of(null);
        }),
      )
      .subscribe((tenant) => {
        if (tenant) {
          return this._tenant.next(tenant);
        }
        this._tenant.next(null);
      });
  }

  getUserTenant(user: UserObj) {
    return this.afs
      .doc(`/tenants/${user.tenantId}`)
      .get()
      .pipe(
        map((snap) =>
          snap.exists
            ? new Tenant({
                ...(snap.data() as Tenant),
                id: snap.id,
              })
            : null,
        ),
      );
  }

  /**
   * Gets all tenants
   * Called with snapshotChanges so the FE can observe changes
   * @returns Observable of an array of Tenants
   */
  getAllTenants(): Observable<Tenant[]> {
    return this.afs
      .collection('tenants')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((s) => {
            return new Tenant({
              ...(s.payload.doc.data() as Tenant),
              id: s.payload.doc.id,
            });
          });
        }),
      );
  }

  /**
   * Checks if tenant is unique
   * @param tenant string
   * @returns true if tenant is unique, false if it isn't.
   */
  async checkIfTenantUnique(tenant: string): Promise<boolean> {
    return this.afs
      .collection('tenants')
      .doc(tenant)
      .get()
      .pipe(
        take(1),
        map((snap) => !snap.exists),
      )
      .toPromise();
  }

  /**
   *
   * @param data CreateTenantData
   * @returns
   */
  async createNewTenant(data: CreateTenantData): Promise<void> {
    try {
      const user = await this.userService.user.pipe(take(1)).toPromise();
      // double check to make sure user is part of the spark tenant
      if (!user?.canCreateTenant) {
        return;
      }
      const callable = this.fns.httpsCallable('createNewTenant');
      return callable(data).pipe(take(1)).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async saveLabPanels(panelInfo, tenantId){
    const ref = this.afs.collection(`tenants/${tenantId}/drug-and-alcohol-config`).doc('labPanels');
    return ref.set(panelInfo, { merge: true });
  }

}

