import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-conrail',
  templateUrl: './conrail.component.html',
  styleUrls: ['./conrail.component.scss']
})
export class ConrailComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    window.location.href = `${environment.samlServiceUrl}/admin/conrail/login`;
  }

}
