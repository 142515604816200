import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { from } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
      private auth: AuthService,
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // need to do it this way to add in an async request to getIdToken
    return from(this.handle(req, next));
  }

  /**
   * handles the async request to getIdToken
   * taken from this answer in stack overflow: https://stackoverflow.com/a/57712118
   * @param req
   * @param next
   */
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    // gets fresh auth token
    const authToken = await this.auth.getIdToken();
    if (authToken) {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return next.handle(authReq).toPromise();
    }
    return next.handle(req).toPromise();
  }
}
