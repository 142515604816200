import { TaskCategoryDoc } from '../dto';
import { TaskCategory } from './task-category';

export class TaskCategoryDuplicate extends TaskCategory {
  parentId: string; // The id of the task category this is duplicating

  constructor(catDoc: TaskCategoryDoc, parentId: string) {
    super(catDoc);
    this.parentId = parentId;
  }

  public toFirestoreObject(): Object {
    const data = { ...this };
    delete data.parentId;
    return data;
  }
}
