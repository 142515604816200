import firebase from 'firebase/compat/app';

import { Feedback, Subtask, TaskDoc } from '../dto/taskDoc';
import { createDate } from '../utils/dates/create-date';

export class Task implements TaskDoc {
  // TODO use this everywhere possible instead of TaskDoc
  archived: boolean = false;
  createdBy: string = ''; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp = null;
  id: string = '';
  lastUpdatedOn: Date | firebase.firestore.Timestamp = null;

  categoryId: string = '';
  categoryName: string = '';
  checklistId: string = '';
  details: string = '';
  feedback: Feedback[] = [];
  name: string = '';
  regulated: boolean = false;
  requiredPasses: number = null;
  updatedBy: string = '';  // AccountHolder Id
  subdivision?: string;
  startMilePost?: string;
  endMilePost?: string;
  trainJobId?: string;
  subtasks: Subtask[] = [];

  public constructor(properties?: any) {
    // TODO make this more robust and add missing subtask arrays
    Object.assign(this, properties);
    this.createdOn = properties.createdOn ? createDate(properties.createdOn) : new Date();
    this.lastUpdatedOn = properties.lastUpdatedOn ? createDate(properties.lastUpdatedOn) : new Date();
  }

  public firestoreObject(): Object {
    return { ...this };
  }
}
