import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ns',
  templateUrl: './ns.component.html',
  styleUrls: ['./ns.component.scss']
})
export class NsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = `${environment.samlServiceUrl}/admin/ns/login`;
  }

}
