import { createDate } from '../../utils/dates/create-date';
import { QualificationDoc, QualificationRefreshPeriod, QualificationRequirementType, RequirementGroupDoc } from '../../dto/qualifications';
import { BirthdayDisplay, QualificationCard, QualificationType } from '../../enums/qualifications';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

export class Qualification implements QualificationDoc {
  id: string;
  archived: boolean;
  createdOn: Date;
  lastUpdatedOn: Date;
  createdBy: string;
  updatedBy: string;

  cardDescription?: string;
  cardType?: QualificationCard;
  expirationPeriod?: QualificationRefreshPeriod;
  requirementRefIds: string[];
  requirementGroups: RequirementGroupDoc[];
  summary?: string;
  title: string;
  type: QualificationType;
  uniqueId?: string;
  dobType?: BirthdayDisplay;
  useReqsForExpiration?: boolean;

  countUserAssignment: number;
  countUserExpiring: number;
  countUserExpired: number;

  constructor(qualification?: Qualification | QualificationDoc) {
    this.id = qualification ? qualification.id : null;
    this.archived = qualification ? qualification.archived : false;
    this.createdOn = qualification.createdOn ? createDate(qualification.createdOn) : new Date();
    this.lastUpdatedOn = qualification.lastUpdatedOn ? createDate(qualification.lastUpdatedOn) : new Date();
    this.createdBy = qualification ? qualification.createdBy : null;
    this.updatedBy = qualification ? qualification.updatedBy : null;

    this.cardDescription = qualification.cardDescription;
    this.cardType = qualification.cardType;
    this.expirationPeriod = qualification.expirationPeriod;
    this.requirementRefIds = qualification.requirementRefIds || [];
    this.requirementGroups = qualification.requirementGroups || [];
    this.summary = qualification.summary;
    this.title = qualification ? qualification.title : null;
    this.type = qualification.type;
    this.uniqueId = qualification.uniqueId;
    this.dobType = qualification.dobType;
    this.useReqsForExpiration = qualification.useReqsForExpiration;

    this.countUserAssignment = qualification.countUserAssignment;
    this.countUserExpired = qualification.countUserExpired;
    this.countUserExpiring = qualification.countUserExpiring;
  }

  public static pathToReferenceType(path: string) {
    if (path.includes('external-requirements')) {
      return QualificationRequirementType.EXTERNAL_REQUIREMENT;
    }
    if (path.includes('checklists')) {
      return QualificationRequirementType.CHECKLIST;
    }
    if (path.includes('test-name')) {
      return QualificationRequirementType.OPERATIONS_TEST;
    }
    return null;
  }
}

export class QualificationSearchModel {
  doc: QualificationDoc;
  id: string;
  tenantId: string;
  constructor(doc: QueryDocumentSnapshot<QualificationDoc>, tenantId: string) {
    this.doc = doc.data();
    this.id = doc.id;
    this.tenantId = tenantId;
  }

  public toAlgolia() {
    const { archived, uniqueId, title, createdOn, lastUpdatedOn, type } = this.doc;
    const searchObj = {
      objectID: this.id,
      tenantId: this.tenantId,
      title,
      archived,
      uniqueId,
      type,
      //https://www.algolia.com/doc/guides/sending-and-managing-data/prepare-your-data/in-depth/what-is-in-a-record/#dates
      createdOn: new Date(createdOn?.valueOf()).getTime(),
      lastUpdatedOn: new Date(lastUpdatedOn?.valueOf()).getTime(),
    };
    return searchObj;
  }
}
