import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class AlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  alert(text: string, title?: string, timer: number = 2600) {
    Swal.fire({
      title: title,
      text: text,
      timer: timer,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  complete(text: string, title?: string, ) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'success',
      showCancelButton: false,
      showConfirmButton: true,
    });
  }

  confirm(title: string, text: string, buttonText: string) {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF6900',
      cancelButtonColor: '#37383B',
      confirmButtonText: buttonText,
    });
  }

  confirmWithTextInput(title: string, text: string, buttonText: string) {
    return Swal.fire({
      title,
      text,
      input: 'text',
      inputPlaceholder: '# of days for revocation period',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF6900',
      cancelButtonColor: '#37383B',
      confirmButtonText: buttonText,
    });
  }

  confirmDelete() {
    return Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF6900',
      cancelButtonColor: '#37383B',
      confirmButtonText: 'Yes, delete it!'
    });
  }

}
