import firebase from 'firebase/compat/app';

import { TestTypeDocument } from "../dto";

export class TestType implements TestTypeDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp;
  id: string;
  lastUpdatedOn: Date | firebase.firestore.Timestamp;
  updatedBy: string;  // AccountHolder Id

  label: string;

  constructor(type: TestType | TestTypeDocument) {
    this.assignValues(type);
  }

  assignValues(type: TestType | TestTypeDocument) {
    this.archived = type.archived !== undefined ? type.archived : undefined;
    this.createdBy = type.createdBy || undefined;
    this.createdOn = type.createdOn || undefined;
    this.id = type.id || undefined;
    this.lastUpdatedOn = type.lastUpdatedOn || undefined;
    this.updatedBy = type.updatedBy || undefined;
    this.label = type.label || undefined;
  }

  firestoreObject() {
    return { ...this }
  }
}
