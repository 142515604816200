import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { NgModule } from '@angular/core';
import { BoolToYesNoPipe } from './pipes/bool-to-yes-no.pipe';

@NgModule({
  declarations: [BoolToYesNoPipe],
  imports: [
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
  ],
  providers: [
    ScreenTrackingService, UserTrackingService,
    { provide: CONFIG, useValue: {
      allow_ad_personalization_signals: false,
      anonymize_ip: true
    } },
  ],
})
export class CoreModule {}
