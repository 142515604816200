import firebase from 'firebase/compat/app';
import { FirestoreBaseDocument } from "../dto/firestoreBaseDoc";

export class TestSubtype implements FirestoreBaseDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp;
  id: string;
  parentId: string;
  lastUpdatedOn: Date | firebase.firestore.Timestamp;
  updatedBy: string;  // AccountHolder Id

  label: string;

  constructor(type: TestSubtype) {
    this.assignValues(type);
  }

  assignValues(type: TestSubtype) {
    this.archived = type.archived !== null ? type.archived : null;
    this.createdBy = type.createdBy || null;
    this.createdOn = type.createdOn || null;
    this.parentId = type.parentId || null;
    this.id = type.id || null;
    this.lastUpdatedOn = type.lastUpdatedOn || null;
    this.updatedBy = type.updatedBy || null;
    this.label = type.label || null;
  }

  firestoreObject() {
    return { ...this }
  }
}
