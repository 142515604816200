import { convertTimestamp } from '../utils/dates/convertTimestamp';

import { TestTerminalDocument } from '../dto';

export class TestTerminal implements TestTerminalDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id

  label: string;
  parentId: string; // subdivision or district Id
  startMilepost?: number;
  endMilepost?: number;

  constructor(terminal: TestTerminal | TestTerminalDocument) {
    this.assignValues(terminal);
  }

  assignValues(terminal: TestTerminal | TestTerminalDocument) {
    this.archived = terminal.archived === false ? terminal.archived : terminal.archived || undefined;
    this.createdBy = terminal.createdBy || undefined;
    this.id = terminal.id || undefined;
    this.label = terminal.label || undefined;
    this.parentId = terminal.parentId || undefined;
    this.createdOn = convertTimestamp(terminal.createdOn);
    this.lastUpdatedOn = convertTimestamp(terminal.lastUpdatedOn);
    // handle optional values
    if (terminal.startMilepost) { this.startMilepost = terminal.startMilepost; }
    if (terminal.endMilepost) { this.endMilepost = terminal.endMilepost; }
  }

  firestoreObject() {
    return { ...this }
  }
}
