import { convertTimestamp } from '../utils/dates/convertTimestamp';

import { TestSubdivisionDocument, TestTerminalDocument } from '../dto';

export class TestSubdivision implements TestSubdivisionDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date;
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id
  label: string // subdivision
  terminals: TestTerminalDocument[]

  constructor(subdivision: TestSubdivision | TestSubdivisionDocument ) {
    this.assignValues(subdivision);
  }

  assignValues(subdivision: TestSubdivision | TestSubdivisionDocument) {
    this.archived = subdivision.archived === false ? subdivision.archived : subdivision.archived || undefined;
    this.createdBy = subdivision.createdBy || undefined;
    this.id = subdivision.id || undefined;
    this.updatedBy = subdivision.updatedBy || undefined;
    this.label = subdivision.label || undefined;
    this.terminals = subdivision.terminals || undefined;
    this.createdOn = convertTimestamp(subdivision.createdOn);
    this.lastUpdatedOn = convertTimestamp(subdivision.lastUpdatedOn);
  }

  firestoreObject() {
    const terminals = this.terminals.map(t => ({ ...t}));
    return { ...this, terminals }
  }
}
