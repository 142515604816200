import firebase from 'firebase/compat/app';
import { FirestoreBaseDocument } from "../dto/firestoreBaseDoc";

export class TestDistrict implements FirestoreBaseDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp;;
  id: string;
  lastUpdatedOn: Date | firebase.firestore.Timestamp;;
  updatedBy: string;  // AccountHolder Id

  label: string;
  parentId: string; // subdivision Id

  constructor(district: TestDistrict) {
    this.assignValues(district);
  }

  assignValues(district: TestDistrict) {
    this.archived = district.archived === false ? district.archived : district.archived || undefined;
    this.createdBy = district.createdBy || undefined;
    this.id = district.id || undefined;
    this.label = district.label || undefined;
    this.parentId = district.parentId || undefined;
    this.createdOn = district.createdOn;
    this.lastUpdatedOn = district.lastUpdatedOn;

  }

  firestoreObject() {
    return { ...this }
  }
}
