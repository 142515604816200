import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { UserService } from '../../../core/services/user.service';
/**
 * Handles callback from the SSO service.
 * Expects custom firebase token as part of URL
 * /sso/callback/fbtokentextgoeshereandisverylong
 *
 * @export
 * @class CallbackComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})

export class CallbackComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private sweetAlert: AlertComponent,
    private userService: UserService,
  ) { }

  ngOnInit() {
    // no unsubscribe is needed as Router manages it's observables
    const token = this.activatedRoute.snapshot.paramMap.get('token');

    this.authService.signInWithFBToken(token).then(result => {
      if (!result.user?.uid) {
        this.authService.signOut().then(() => {
          this.router.navigate(['/unauthorized']);
        });
        return;
      }

      // Check that user profile has been created. Valid auth doesn't mean Rail Tasker has been setup for this user yet.
      this.userService.fetchUserById(result.user.uid).then(userObj => {
        if (userObj.id.length === 0 || !userObj.active) {
          this.authService.signOut().then(() => {
            this.router.navigate(['/unauthorized']);
          });
        } else {
          if (!userObj.permissions.checklists && !userObj.permissions.reports
            && !userObj.permissions.users
            && !userObj.permissions.reports
            && !userObj.permissions.groups) {
              this.router.navigate(['/unauthorized']);
            }
          this.router.navigate(['/dashboard/checklists']);
        }
      });
    }).catch(err => {
      this.sweetAlert.alert('Authorization failed.');
      this.router.navigate(['/login']);
    });
  }

}
