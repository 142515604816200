import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { TenantService } from '@core/services/tenant.service';
import { take } from 'rxjs/operators';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  public logo = '';

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    try {
      const currentTenant = await this.tenantService.getUserTenant(this.userService.currentUser)
        .pipe(take(1)).toPromise();
      this.logo = `../../../assets/images/${currentTenant.isPeopleTasker ? 'peopletasker' : 'railtasker'}-logo.png`;
    } catch (error) {
      console.error(error);
    }
  }

  public goToChecklistBuilder() {
    this.router.navigate([`/dashboard/checklists`]);
  }

}
