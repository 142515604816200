import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '../core/core.module';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';

import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdminPassResetComponent } from '../dashboard/userlist/components/admin-pass-reset/admin-pass-reset.component';
import { SsoComponent } from './sso/sso.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LogoutComponent } from './logout/logout.component';
import { CallbackComponent } from './sso/callback/callback.component';
import { Auth0Component } from './sso/auth0/auth0.component';
import { BnsfComponent } from './sso/bnsf/bnsf.component';
import { Kcs2Component } from './sso/kcs2/kcs2.component';
import { LogoutSuccessComponent } from './logout-success/logout-success.component';
import { EnterpriseComponent } from './sso/enterprise/enterprise.component';
import { NsComponent } from './sso/ns/ns.component';
import { CpkcComponent } from './sso/cpkc/cpkc.component';
import { ConrailComponent } from './sso/conrail/conrail.component';
import { OmnitraxComponent } from './sso/omnitrax/omnitrax.component';

@NgModule({
    declarations: [
        AdminPassResetComponent,
        LoginComponent,
        LoginFormComponent,
        NotFoundComponent,
        SsoComponent,
        UnauthorizedComponent,
        LogoutComponent,
        CallbackComponent,
        Auth0Component,
        BnsfComponent,
        Kcs2Component,
        LogoutSuccessComponent,
        EnterpriseComponent,
        NsComponent,
        CpkcComponent,
        ConrailComponent,
        OmnitraxComponent,
    ],
    imports: [
        AuthRoutingModule,
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        LoginComponent,
    ]
})
export class AuthModule { }
