import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { UserObj } from '../../../../core/models/user';
import { UserService } from '../core/services/user.service';
import { CallableFunctions } from '../../../../core/enums/callable-functions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authState = new BehaviorSubject<firebase.User>(null);
  public readonly authState = this._authState.asObservable();
  private usersCollection: AngularFirestoreCollection<UserObj>;
  public token = null; // needed for http requests
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private afFunctions: AngularFireFunctions,
    private userService: UserService,
  ) {
    this.usersCollection = this.afs.collection<UserObj>('users');
    this.afAuth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        this.afAuth.authState
          .pipe(
            map((user: firebase.User) => {
              this._authState.next(user);
              return user;
            }),
            tap(async (user: firebase.User) => {
              // get token for user from firebase
              if (user) {
                this.token = await this.getIdToken();
              }
            }),
            switchMap((user: firebase.User) => {
              // checks firebase user for provider data.
              // will probably need to double check this does what we want it to once SSO has been hashed out.
              if (
                user &&
                user.providerData[0] &&
                user.providerData[0]?.uid !== null &&
                user.providerData[0]?.providerId !== 'password'
              ) {
                return this.userService.fetchUserByUsername(
                  user.providerData[0].providerId.substring(5),
                  user.providerData[0].uid,
                );
              }
              return user
                ? this.userService.getUserById(user.uid)
                : Promise.resolve(null);
            }),
          )
          .subscribe((user) => {
            this.userService.setUserAndTenantId(user);
          });
      });
  }

  // returns true if the user is logged in
  get authenticated(): boolean {
    return this._authState.getValue() !== null;
  }

  // returns current user data
  get currentUserAuthState(): any {
    return this.authenticated ? this._authState.getValue() : null;
  }

  // returns authstate observable
  get currentAuthStateObservable(): any {
    return this.afAuth.authState;
  }

  // returns current user UID
  get uid(): string {
    return this.authenticated ? this._authState.getValue().uid : null;
  }

  // returns current user email
  get email(): string {
    return this.authenticated ? this._authState.getValue().email : null;
  }

  // sign in using email, password
  public async signIn(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    const userCred = await this.afAuth.signInWithEmailAndPassword(
      email,
      password,
    );
    const user = await this.userService.fetchUserById(userCred.user.uid);
    if (user && user.active) {
      return userCred;
    } else {
      await this.signOut();
      throw new Error('Not allowed: Inactive user account.');
    }
  }

  public signInSSO(providerId: string): Promise<void> {
    const provider = new firebase.auth.SAMLAuthProvider(providerId);
    return this.afAuth.signInWithRedirect(provider);
  }

  public signInWithFBToken(
    fbToken: string,
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithCustomToken(fbToken);
  }

  public async getRedirectResults() {
    return this.afAuth.getRedirectResult();
  }

  // signs out user
  public signOut() {
    return this.afAuth.signOut();
  }

  // register with email and password
  public async register(email: string, password: string, displayName: string) {
    const registerFunction = this.afFunctions.httpsCallable(
      CallableFunctions.createNewUser,
    );
    const { tenantId } = await this.userService.user.pipe(take(1)).toPromise();
    return registerFunction({
      email,
      password,
      displayName,
      tenantId,
    }).toPromise();
  }

  // reset password
  public async resetPassword(login: string, isEmail: boolean) {
    if (!isEmail) {
      let userEmail;
      const loginEmail = this.userService.appendEmailToUsername(login);
      const uid = await this.getUserByEmail(loginEmail);
      this.userService.getUserById(uid).subscribe(async (user) => {
        userEmail = user.email;
        const sendMail = this.afFunctions.httpsCallable(
          CallableFunctions.sendPasswordResetEmail,
        );
        const results = await sendMail({ email: userEmail, uid })
          .pipe(take(1))
          .toPromise();
      });
      return;
    } else {
      return this.afAuth.sendPasswordResetEmail(login);
    }
  }

  /**
   * Checks to see if email is registered.
   * @param email string
   * @returns true if email is registered, false if it isn't
   */
  public async checkIfEmailIsRegistered(email: string) {
    try {
      // needed because there is a quota on:
      // this.afAuth.auth.fetchSignInMethodsForEmail(email);
      // able to bypass this by going through admin sdk.
      const checkFunction = this.afFunctions.httpsCallable(
        CallableFunctions.emailIsRegistered,
      );
      const result = await checkFunction({ email }).pipe(take(1)).toPromise();
      if (result && result.isRegistered) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('error checking email', error);
      throw error;
    }
  }

  public async getUserByEmail(email: string) {
    const getUidFunction = this.afFunctions.httpsCallable(
      CallableFunctions.getUserUidByEmail,
    );
    const results = await getUidFunction({ email }).toPromise();
    return results.uid;
  }

  public async decodeUserId(encodedId: string) {
    const getUidFunction = this.afFunctions.httpsCallable(
      CallableFunctions.getUserId,
    );
    const results = await getUidFunction({ encodedId }).toPromise();
    return results.uid;
  }

  public changePassword(password: string): Promise<boolean> {
    return firebase
      .auth()
      .currentUser.updatePassword(password)
      .then(
        (res) => Promise.resolve(true),
        (err) => Promise.resolve(false),
      );
  }

  // need to reauthenticate before changing the password
  public reauthenticate(password: string): Promise<boolean> {
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.email,
      password,
    );
    return firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credentials)
      .then(
        (res) => Promise.resolve(true),
        (err) => Promise.resolve(false),
      );
  }

  async getIdToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.getIdToken(true);
    } else {
      return null;
    }
  }
}
