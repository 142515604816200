export enum CallableFunctions {
  createNewUser = 'createNewUser',
  updateUserPassword = 'updateUserPassword',
  getUserUidByEmail = 'getUserUidByEmail',
  sendPasswordResetEmail = 'sendPasswordResetEmail',
  getUserId = 'getUserId',
  emailIsRegistered = 'emailIsRegistered',
  searchDATClinic = 'searchDATClinic',
  sendDATOrder = 'sendDATOrder',
  emailAuthForm = 'emailAuthForm',
  batchedAlgoliaSyncing = 'batchedAlgoliaSyncing',
  getCrewCallData = 'getCrewCallData',
  countActiveTrainees = 'countActiveTrainees',
  getUserGroupCounts = 'getUserGroupCounts',
  countActiveUsers = 'countActiveUsers'
}

