import firebase from 'firebase/compat/app';
import { ExternalRequirementDoc } from './externalRequirementDoc';

export enum ExternalRequirementCompletionStatus {
  PASS = 'pass',
  FAIL = 'fail',
  PENDING = 'pending',
}

export interface ExternalRequirementCompletionDoc
  extends Omit<ExternalRequirementDoc, 'countQualifications' | 'countUserAssignment' | 'countExpiring' | 'countExpired'> {
  userId: string;
  username: string;
  completedOn: firebase.firestore.Timestamp;
  status: ExternalRequirementCompletionStatus;
  comment?: string;
  fileUrl?: string;
  instructorId?: string;
}

/*
check into auto populating from an Docebo API thing
 */

export function fromDoceboEvent(data) {
  return {
    userId: data.user_id,
    username: data.user_id,
    completedOn: new firebase.firestore.Timestamp(data.completion_date, 0),
    status: data.status,
    uniqueId: data.course_id,
  } as ExternalRequirementCompletionDoc;
}

/**
 *
 * @param doceboStatus
 * Possible docebo values are “subscribed”, “in_progress”, “completed”,
 * “waiting”, “subscription_to_confirm”, “suspended”, “overbooking”
 *  Theoretically, only "completed" should come back through this method. There doesn't seem to be a failure state.
 */
function getStatus(doceboStatus) {
  switch (doceboStatus) {
    case 'completed':
      return 'pass';
    default:
      return 'pending';
  }
}
